import React from 'react';
import { Helmet } from 'react-helmet';
import { GatsbyImg } from '../../services/img-location/img-location-service';
import { graphql } from 'gatsby';
import { CINCINNATI_PER_DIEM_PROPS } from '../../constants/landing-page-props';
import LandingPageApplication from '../../components/landing-page-application';

export default ({ data }) => {
  const trustaffLogoImage = new GatsbyImg('trustaff-logo', data);
  const backgroundImage = new GatsbyImg('cincy-background', data);
  const horizontalBannerImage = new GatsbyImg('horizontal-nurses-tps', data);

  return (
    <>
      <Helmet>
        <title>Trustaff - Cincinnati Per Diem</title>
        <meta charSet="utf-8" />
        <meta
          name="Description"
          content="Complete the form below to get the ball rolling! A career specialist will be in touch shortly to answer any questions you have and begin your job search."
        />
        <meta property="og:title" content="Get started today" />
        <meta
          property="og:description"
          content="Complete the form below to get the ball rolling! A career specialist will be in touch shortly to answer any questions you have and begin your job search."
        />
        <meta property="og:image" content={backgroundImage.ogImgUrl} />
        <meta
          property="og:url"
          content="https://www.trustaff.com/cincinnati-per-diem/"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content="Trustaff" />
        <meta property="og:type" content="website" />
        <meta name="twitter:site" content="@Trustaff" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>

      <LandingPageApplication
        landingPageProps={CINCINNATI_PER_DIEM_PROPS}
        trustaffLogoImgFluid={trustaffLogoImage.fluidImg}
        backgroundImageFluid={backgroundImage.fluidImg}
        horizontalBannerFluid={horizontalBannerImage.fluidImg}
      />
    </>
  );
};

export const pageQuery = graphql`
  {
    allFile(
      filter: {
        relativePath: { regex: "/(trustaff-logo.png|^cincinnati-per-diem)/" }
      }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 2400) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
